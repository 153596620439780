<template>
    <text-box>
        <div class="p-title">Öffnungszeiten</div>
        Mo – Fr | 9:00 – 18:00 Uhr
        <br><br>
        <div class="p-title">Telefon</div>
        033633 65377
        <br><br>
        <div class="p-title">E-Mail</div>
        info@ak-kfz.de
        <br><br>
    </text-box>
</template>

<script>
import TextBox from "@/components/TextBox.vue";

export default {
    name: "ContactBox",
    components: {TextBox}
}
</script>

<style scoped>

</style>